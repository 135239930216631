import React, { useContext } from "react";
import {
  Outlet,
  useParams,
  useNavigate,
  useOutletContext,
} from "react-router-dom";

import AppContext from "../../contexts/appContext";
import ErrorBlock from "../ErrorBlock";
import { ButtonContainer, PrimaryButton } from "../Button";
import { AppOutlet } from "../AppRoute/appRoute";

import { useTranslate } from "@/i18n";
import routes from "@/routes/routes";
import { Subscription } from "@/fetch/mappers/subscriptionMapper";
import { User } from "@/services/amedia-user/login";
export interface SubscriptionOutlet {
  readonly subscription: Subscription;
  readonly user: User;
}

const SubscriptionRoute: React.FC = () => {
  const t = useTranslate(messages);
  const { user } = useOutletContext<AppOutlet>();
  const navigate = useNavigate();
  const { subscriptions } = useContext(AppContext);
  const { subscriptionId } = useParams();
  const subscription =
    subscriptions.data.find((s) => s.id === subscriptionId) ||
    subscriptions.data.length > 0
      ? subscriptions.data[0]
      : undefined;

  const NoActiveSubscription = () => (
    <ErrorBlock title={t("title")}>
      <p>{t("message")}</p>
      <ButtonContainer>
        <PrimaryButton onClick={() => navigate(routes.myPage.path())}>
          {t("cta")}
        </PrimaryButton>
      </ButtonContainer>
    </ErrorBlock>
  );

  return subscription ? (
    <div data-testid="subscription-outlet">
      <Outlet context={{ subscription, user }} />
    </div>
  ) : (
    <NoActiveSubscription />
  );
};

export default SubscriptionRoute;

const messages = {
  title: {
    nb: "Fant ikke abonnement",
    nn: "Fann ikkje abonnement",
  },
  message: {
    nb: "Brukeren du er logget inn med har ikke et aktivt abonnement.",
    nn: "Brukaren du er logga inn med har ikkje noko aktivt abonnement.",
  },
  cta: {
    nb: "Gå til Min side",
    nn: "Gå til Mi side",
  },
};
